(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.editorSkinsData = f()}})(function(){var define,module,exports;
/* Autogenerated file. Do not modify */
'use strict';
var skins = {};
 skins['wysiwyg.viewer.skins.appinputs.AppsTextAreaInputSkin'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "textarea",
      "textarea",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_err"
      ],
      {}
    ]
  ],
  "params": {
    "fntlbl": "FONT",
    "txtlbl": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "fntlbl": "font_8",
    "txtlbl": "color_15"
  },
  "css": {
    "%": "position:relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%textarea": "resize:none;width:100% !important;-webkit-box-flex:1;-webkit-flex:1;flex:1;overflow:auto;",
    "% p": "display:none;position:absolute;",
    "%[data-state~=\"invalid\"] %textarea": "color:#d00;border-color:#d00;",
    "% textarea:focus": "color:#444  !important;border-color:#000 !important;",
    "%_err": "position:absolute;top:50%;left:0;height:1px;width:1px;",
    "%[data-state~=\"invalid\"] %_err:before": "color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:\"!\";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;",
    "%[data-state~=\"invalid\"] %_err:after": "color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:\"►\";position:absolute;top:10px;left:8px;",
    "%label": "[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;"
  }
}
 skins['wysiwyg.viewer.skins.appinputs.AppsTextAreaInputSkinNoValidation'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "textarea",
      "textarea",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_err"
      ],
      {}
    ]
  ],
  "params": {
    "fntlbl": "FONT",
    "txtlbl": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "fntlbl": "font_8",
    "txtlbl": "color_15"
  },
  "css": {
    "%": "position:relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%textarea": "resize:none;width:100% !important;-webkit-box-flex:1;-webkit-flex:1;flex:1;overflow:auto;",
    "% p": "display:none;position:absolute;",
    "%[data-state~=\"invalid\"] %textarea": "color:#d00;border-color:#d00;",
    "% textarea:focus": "color:#444  !important;border-color:#000 !important;",
    "%_err": "position:absolute;top:50%;left:0;height:1px;width:1px;",
    "%[data-state~=\"invalid\"] %_err:before": "color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:\"!\";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;",
    "%[data-state~=\"invalid\"] %_err:after": "color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:\"►\";position:absolute;top:10px;left:8px;",
    "%label": "[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;"
  }
}

return skins;

});
